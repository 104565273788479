import React, { useMemo, useState } from "react";
import { SecondaryButtons } from "../common/buttons";
import { RadioGroup, RadioGroupItem, Label, Badge, Input } from "../shadcn";
import { AsyncValidateOnTypeInput } from "../core/AsyncValidateOnTypeInput";
import FileInputButton from "../core/FileInputButton";
import FileDragAndDropTarget from "./FileDragAndDropTarget";
import { LOOKUP_TYPES, CONTACT_MORE_CREDITS } from "./constants";
import ComboBox from "../core/ComboBox";
import { useGetCustomUploadsSourcesQuery } from "src/hooks/useCustomUploads";

const NUM_PREVIEW_ROWS = 20;

const StepContainer = ({ children }) => (
  <div className="flex flex-col gap-2 border-2 p-4 rounded-2xl">{children}</div>
);

const Step = ({ stepNumber, stepText, children }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row font-bold text-[16px] text-[#666666] gap-2 items-center">
        <div className="w-5 h-5 bg-[#999999] text-primary-foreground rounded-full flex items-center justify-center font-bold text-sm">
          {stepNumber}
        </div>
        {stepText}
      </div>
      <div className="font-normal text-[14px] text-[#999999]">{children}</div>
    </div>
  );
};

const AddSource = ({ listSource, onListSourceChange, stepNumber }) => {
  const { data: sources } = useGetCustomUploadsSourcesQuery();
  const [newItem, setNewItem] = useState(null);

  const handleListSourceChange = (newSelected, isNew) => {
    isNew && setNewItem(newSelected);
    onListSourceChange(newSelected);
  };

  const sourcesList = useMemo(() => {
    return [
      ...(sources || []).map(({ source }) => ({
        label: source,
        value: source,
      })),
      ...(newItem ? [newItem] : []),
    ];
  }, [sources, newItem]);

  return (
    <StepContainer>
      <Step stepNumber={stepNumber} stepText="Add Source (Optional)">
        Specify a source for this look up to help you remember where this data
        came from.
      </Step>
      <ComboBox
        isNewItemsAllowed
        placeholder="Select your field"
        emptyText="No matching fields"
        list={sourcesList}
        value={listSource}
        onSelect={(newSelected, isNew) => {
          handleListSourceChange(
            {
              label: newSelected ? newSelected.value : null,
              value: newSelected ? newSelected.value : null,
            },
            isNew
          );
        }}
      />
    </StepContainer>
  );
};

const AddName = ({
  listName,
  onListNameChange,
  stepNumber,
  importNameValidator,
}) => {
  const handleListNameChange = (name) => {
    onListNameChange(name);
  };
  return (
    <StepContainer>
      <Step stepNumber={stepNumber} stepText="Name Your List">
        Specify a name for this lookup so you can refer back to it in the
        future.
      </Step>
      <AsyncValidateOnTypeInput
        onValidate={importNameValidator}
        value={listName}
        onChange={handleListNameChange}
        placeHolder="Name your list"
      />
    </StepContainer>
  );
};

const SelectFile = ({ onFileSelected, userCredits, stepNumber }) => (
  <FileDragAndDropTarget
    onFileSelected={onFileSelected}
    className="flex flex-col gap-2 border-2 p-4 rounded-2xl"
  >
    <Step stepNumber={stepNumber} stepText="Select File">
      <div className="flex flex-col gap-1">
        <p>
          Click the <span className="font-bold">Select File</span> button to
          select a file or drag and drop it here.
        </p>
        <p>Valid file types include .xls, .csv or .txt.</p>
        <p>Credits remaining: {userCredits}</p>
      </div>
    </Step>
    <FileInputButton
      onFileSelected={onFileSelected}
      buttonLabel="Select File"
    />
  </FileDragAndDropTarget>
);

const SelectLookupType = ({ lookupType, onLookupTypeChange, stepNumber }) => (
  <StepContainer>
    <Step stepNumber={stepNumber} stepText="Select Look Up Type">
      <p className="font-normal text-[14px] text-[#999999]">
        Pick one of the following. We’ll also provide the{" "}
        <span className="font-bold">email</span> and{" "}
        <span className="font-bold">social media information</span>.{" "}
      </p>
    </Step>
    <RadioGroup
      value={lookupType}
      onValueChange={onLookupTypeChange}
      className="space-y-2"
    >
      <div className="flex items-start space-x-3">
        <RadioGroupItem value={LOOKUP_TYPES.reverseAddress} id="address" />
        <div className="grid gap-1.5 leading-none">
          <Label htmlFor="address" className="text-sm font-medium text-[#666666]">
            Find missing phone numbers
          </Label>
          <p className="text-[12px] text-[#999999]">
            "Address" is required for phone number lookups. Providing a "Name"
            in your file will greatly boost the accuracy.
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-3">
        <RadioGroupItem value={LOOKUP_TYPES.reversePhone} id="phone" />
        <div className="grid gap-1.5 leading-none">
          <Label
            htmlFor="phone"
            className="text-sm font-medium text-[#666666]"
          >
            Find missing addresses
          </Label>
          <p className="text-[12px] text-[#999999]">
            "Phone number" is required for address lookups. Providing a "Name"
            in your file will greatly boost the accuracy.
          </p>
        </div>
      </div>
    </RadioGroup>
  </StepContainer>
);

const steps = ["selectLookupType", "selectFile", "addName", "addSource"].map(
  (stepName, index) => ({
    stepNumber: index + 1,
    stepName,
  })
);

const ConfigureUpload = ({
  importNameValidator,
  userCredits,
  numRecords,
  jsonData,
  onFileSelected,
  onListNameChange,
  fileName,
  listName,
  fileColumnsKeys,
  onListSourceChange,
  onLookupTypeChange,
  lookupType,
  listSource,
}) => {

  return (
    <section className="flex flex-col gap-8 pageMargin h-full">
      <div className={`grid grid-cols-1 md:grid-cols-${steps.length} gap-6`}>
        {steps.map(({ stepName, stepNumber }) => {
          switch (stepName) {
            case "selectLookupType":
              return (
                <SelectLookupType
                  key={"selectLookupType"}
                  lookupType={lookupType}
                  onLookupTypeChange={onLookupTypeChange}
                  stepNumber={stepNumber}
                />
              );
            case "selectFile":
              return (
                <SelectFile
                  key="selectFile"
                  onFileSelected={onFileSelected}
                  userCredits={userCredits}
                  stepNumber={stepNumber}
                />
              );
            case "addName":
              return (
                <AddName
                  key="addName"
                  listName={listName}
                  importNameValidator={importNameValidator}
                  onListNameChange={onListNameChange}
                  stepNumber={stepNumber}
                />
              );
            case "addSource":
              return (
                <AddSource
                  key="addSource"
                  listSource={listSource}
                  onListSourceChange={onListSourceChange}
                  stepNumber={stepNumber}
                />
              );
          }
        })}
      </div>

      {!!numRecords && (
        <div className="gap-6 pt-9">
          <div className="flex flex-col md:flex-row  md:justify-between">
            <p className="font-semibold text-[#666666] text-2xl">
              File Preview
            </p>
          </div>
          {userCredits < numRecords && (
            <div>
              <p className="w-full py-4 bg-red-200 p-6 rounded-lg mt-4 text-red-800">
                You currently do not have sufficient credits for the number of
                records ({numRecords}) in the file. You can reduce the number of
                records in the file to match your number of credits (
                {userCredits}) remaining or call our Landvoice team at&nbsp;
                <strong>{CONTACT_MORE_CREDITS}</strong> to purchase more credits
              </p>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-4 py-4 gap-2 md:gap-0">
            {[
              {
                column: "File Name",
                value: fileName,
              },
              {
                column: "Total Rows",
                value: numRecords,
              },
              {
                column: "Total Columns",
                value: fileColumnsKeys?.length,
              },
              {
                column: "Credits Required",
                value: numRecords,
              },
            ].map(({ column, value }) => (
              <div key={column}>
                <p className="text-sm font-semibold text-[#666666]">{column}</p>
                <p className="text-sm font-medium text-[#999999]">{value}</p>
              </div>
            ))}
          </div>
          <div className="relative flex flex-col w-full overflow-auto h-[400px] border rounded-lg">
            <table className="w-full text-left table-auto min-w-max">
              <thead className="sticky top-0 z-10 bg-white">
                <tr>
                  {fileColumnsKeys.map((fileColumnKey) => (
                    <th
                      key={fileColumnKey}
                      className="p-4 border-b border-blue-gray-100 text-sm font-semibold text-[#666666]"
                    >
                      {fileColumnKey}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {jsonData?.slice(0, NUM_PREVIEW_ROWS)?.map((row, rowIndex) => (
                  <tr key={`imported-row-${rowIndex}`}>
                    {fileColumnsKeys.map((fileColumnKey, cellIndex) => (
                      <td
                        className="p-4 border-b border-blue-gray-50 overflow-x-hidden whitespace-nowrap text-ellipsis max-w-80 text-sm font-medium text-nowrap text-[#999999]"
                        key={`imported-row-${rowIndex}-${cellIndex}`}
                      >
                        {row[fileColumnKey] || "N/A"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </section>
  );
};

export default ConfigureUpload;
